import React from "react";
import RadialBarChartComponent from "../subComponents/RadicalBarChart";
import BarChartComponent from "../subComponents/BarChart";
import PieChartComponent from "../subComponents/PieChart";
import LineChartComponent from "../subComponents/LineChart";
import ComposedAreaLineChart from "../subComponents/AreaChart";
import StressGraph from "../subComponents/StressGraph";
import SleepClock from "../subComponents/SleepClock";
import SleepClockV2 from "../subComponents/SleepClockV2";
import StressGraphV2 from "../subComponents/StressGraphV2";
import MultiLevelPieChart from "../subComponents/MultiLevelPieChart";
import SunburstChart from "../subComponents/SunBurstChart";
import HealthRadarChart from "../subComponents/RadarChart";
const ChartsPage = () => {
  const data = [
    { name: "M", value: 30 },
    { name: "T", value: 50 },
    { name: "W", value: 70 },
    { name: "T", value: 90 },
    { name: "F", value: 40 },
    { name: "S", value: 30 },
    { name: "S", value: 50 },
  ];
  const generateRandomMonthData = () => {
    const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];
    const numDaysInMonth = 10;
    const data = [];

    for (let i = 0; i < numDaysInMonth; i++) {
      const dayIndex = i % daysOfWeek.length;
      const randomValue = Math.floor(Math.random() * 10) + 1;
      data.push({ name: daysOfWeek[dayIndex], value: randomValue });
    }
    return data;
  };
  const monthData = generateRandomMonthData();
  const radicalData = [
    { name: "SDNN", value: 6.2, fill: "#8884d8" },
    { name: "SpO2", value: 97, fill: "#83a6ed" },
    { name: "HRV", value: 45, fill: "#ffc658" },
    { name: "HR", value: 78, fill: "#ff6b6b" },
  ];
  const sunburstData = [
    {
      name: "Cardiovascular",
      gradientStart: "#009A51",
      gradientEnd: "#90DEB9",
      children: [
        {
          name: "Heart Rate (HR)",
          value: 78,
          unit: "bpm",
          gradientStart: "#009A51",
          gradientEnd: "#90DEB9",
        },
        {
          name: "Heart Rate Variability (HRV)",
          value: 45,
          unit: "ms",
          gradientStart: "#009A51",
          gradientEnd: "#90DEB9",
        },
      ],
    },
    {
      name: "Respiratory",
      gradientStart: "#FF9900",
      gradientEnd: "#FFD700",
      children: [
        {
          name: "Blood Oxygen (SpO₂)",
          value: 97,
          unit: "%",
          gradientStart: "#FF9900",
          gradientEnd: "#FFD700",
        },
        {
          name: "Respiratory Rate",
          value: 16,
          unit: "breaths/min",
          gradientStart: "#FF9900",
          gradientEnd: "#FFD700",
        },
      ],
    },
  ];
  const multiLevelData = [
    {
      name: "Segment 1",
      value: 20,
      gradientStart: "#009A51",
      gradientEnd: "#90DEB9",
    },
    {
      name: "Segment 2",
      value: 40,
      gradientStart: "#FFC700",
      gradientEnd: "#FFEEB0",
    },
    {
      name: "Segment 3",
      value: 60,
      gradientStart: "#0066CC",
      gradientEnd: "#66B2FF",
    },
    {
      name: "Segment 4",
      value: 70,
      gradientStart: "#F27521",
      gradientEnd: "#FFC59D",
    },
  ];

  return (
    <div className="h-full flex flex-col gap-20 justify-center items-center pb-4">
      <div className="flex gap-4">
        <BarChartComponent
          data={data}
          showXaxis={false}
          showYaxis={false}
          height={100}
          width={100}
          spaceBetweenBars={30}
          barThickness={10}
        />
        <BarChartComponent
          data={data}
          showXaxis={false}
          showYaxis={false}
          height={100}
          width={100}
          spaceBetweenBars={30}
          gradientStart="#F27521"
          gradientEnd="#FFC59D"
          barThickness={10}
        />
        <BarChartComponent
          data={data}
          showXaxis={false}
          showYaxis={false}
          height={100}
          width={100}
          spaceBetweenBars={30}
          gradientStart="#FFC700"
          gradientEnd="#FFEEB0"
          barThickness={10}
        />
        <BarChartComponent
          data={data}
          showXaxis={false}
          showYaxis={false}
          height={100}
          width={100}
          spaceBetweenBars={30}
          gradientStart="#FA7783"
          gradientEnd="#F83446"
          barThickness={10}
        />
        <BarChartComponent
          data={data}
          showXaxis={false}
          showYaxis={false}
          height={100}
          width={100}
          spaceBetweenBars={30}
          gradientStart="#AEAEAE"
          gradientEnd="#868686"
          barThickness={10}
        />
      </div>
      <div className="flex gap-4">
        <PieChartComponent percentage={60} outerRadius={60} />
        <PieChartComponent
          percentage={75}
          gradientStart="#F27521"
          gradientEnd="#FFC59D"
        />
        <PieChartComponent
          percentage={45}
          gradientStart="#FFC700"
          gradientEnd="#FFEEB0"
        />
      </div>
      <BarChartComponent
        data={data}
        width={400}
        showXaxis={true}
        showYaxis={true}
        spaceBetweenBars={30}
        showCartesianGrid={true}
      />
      <ComposedAreaLineChart
        data={monthData}
        width={400}
        showXaxis={true}
        showYaxis={true}
        spaceBetweenBars={30}
        showCartesianGrid={true}
        highlightValue={5}
      />
      <ComposedAreaLineChart
        data={monthData}
        width={400}
        showXaxis={true}
        gradientStart="#F27521"
        gradientEnd="#FFC59D"
        showYaxis={true}
        spaceBetweenBars={30}
        showCartesianGrid={true}
        highlightValue={5}
      />
      <ComposedAreaLineChart
        data={[...monthData, ...monthData, ...monthData]}
        width={400}
        showXaxis={true}
        gradientStart="#FFC700"
        gradientEnd="#FFEEB0"
        showYaxis={true}
        spaceBetweenBars={30}
        showCartesianGrid={true}
      />
      <StressGraphV2 percentage={50} width={127} strokeWidth={15} />
      <StressGraphV2
        percentage={50}
        width={227}
        strokeWidth={20}
        centerFontSize={20}
        percentageFontSize={15}
      />
      <RadialBarChartComponent data={radicalData} />
      <SleepClockV2 />
      <SleepClockV2 size={259} />
      <SleepClockV2 size={400} />
      <MultiLevelPieChart data={multiLevelData} />
      <MultiLevelPieChart data={[...multiLevelData].slice(0, 3)} />
      <SunburstChart data={sunburstData} width={400} height={400} />
      <HealthRadarChart />
    </div>
  );
};

export default ChartsPage;
