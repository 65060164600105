import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const MultiLevelPieChart = ({
  data,
  width = null,
  height = null,
  minInnerRadius = 30,
  minOuterRadius = 50,
}) => {
  const gradientIds = data.map(() => crypto.randomUUID());
  const totalLevels = data.length;
  const radiusStep = (minOuterRadius - minInnerRadius + 20) / totalLevels;
  const radiusArray = data.map((_, index) => ({
    innerRadius: minInnerRadius + index * radiusStep,
    outerRadius: minInnerRadius + (index + 1) * radiusStep,
  }));

  return (
    <ResponsiveContainer
      {...(width ? { width } : {})}
      {...(height ? { height } : {})}
    >
      <PieChart>
        <defs>
          {data.map((entry, index) => (
            <linearGradient
              key={`gradient-${index}`}
              id={`gradient-${index}`}
              x1="0"
              y1="0"
              x2="1"
              y2="1"
            >
              <stop
                offset="0%"
                stopColor={entry.gradientStart}
                stopOpacity={1}
              />
              <stop
                offset="100%"
                stopColor={entry.gradientEnd}
                stopOpacity={1}
              />
            </linearGradient>
          ))}
        </defs>
        {data.map((entry, index) => (
          <Pie
            key={`pie-${index}`}
            data={[entry, { name: "Remaining", value: 100 - entry.value }]}
            cx="50%"
            cy="50%"
            innerRadius={`${radiusArray[index].innerRadius + 4}%`}
            outerRadius={`${radiusArray[index].outerRadius}%`}
            startAngle={90}
            endAngle={-270}
            paddingAngle={2.5}
            dataKey="value"
          >
            {[
              <Cell
                key={`cell-${index}-filled`}
                fill={`url(#gradient-${index})`}
                cornerRadius={10}
              />,
              <Cell
                key={`cell-${index}-remaining`}
                fill="#F3F3F3"
                cornerRadius={10}
              />,
            ]}
          </Pie>
        ))}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default MultiLevelPieChart;
