import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  ReferenceLine,
} from "recharts";

const CustomReferenceLineLabel = ({ viewBox, value }) => {
  const { x, y, width } = viewBox;
  const labelX = x + width / 2;
  const labelY = y;

  return (
    <g>
      <rect
        x={labelX - 25}
        y={labelY - 10}
        width={65}
        height={25}
        fill="#F27521"
        rx={12}
        ry={15}
      />
      <text
        x={labelX + 6}
        y={labelY + 6}
        textAnchor="middle"
        fill="#fff"
        fontSize="12"
      >
        {`Avg ${value.toFixed(2)}`}
      </text>
    </g>
  );
};

const BarChartComponent = ({
  data,
  datakey = "value",
  gradientStart,
  gradientEnd,
  width,
  height,
  showXaxis = true,
  showYaxis = true,
  spaceBetweenBars = 30,
  showCartesianGrid = false,
  axisColor = "#DDDDDD",
  axisTextColor = "#868686",
  margin = { top: 0, right: 0, left: 0, bottom: 0 },
  barThickness = 30,
}) => {
  const [hoveredBarIndex, setHoveredBarIndex] = useState(null);

  const gradientId = `gradient_${Math.random().toString(36).substr(2, 9)}`;

  const averageValue =
    data.reduce((sum, entry) => sum + entry.value, 0) / data.length;

  return (
    <div
      className={`bg-white ${showYaxis ? "border-l-2" : ""}`}
      style={{ borderColor: axisColor }}
    >
      <ResponsiveContainer width={width} height={height}>
        <BarChart data={data} margin={margin}>
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor={gradientStart} stopOpacity={1} />
              <stop offset="100%" stopColor={gradientEnd} stopOpacity={1} />
            </linearGradient>
          </defs>
          {showCartesianGrid && (
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
          )}
          {showXaxis && (
            <XAxis
              dataKey="name"
              stroke={axisColor}
              className="text-body-small"
              tickMargin={10}
              tickLine={false}
              tick={{ fill: axisTextColor }}
            />
          )}
          {showYaxis && (
            <YAxis
              orientation="right"
              stroke={axisColor}
              tick={{ fill: axisTextColor }}
              tickMargin={10}
              axisLine={false}
              tickLine={{ stroke: axisColor }}
              className="text-body-small"
            />
          )}
          <Tooltip content={null} cursor={false} position={{ y: 0 }} />
          <Bar
            dataKey={datakey}
            fill={`url(#${gradientId})`}
            barSize={barThickness}
            radius={[10, 10, 0, 0]}
            isAnimationActive={false}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`url(#${gradientId})`}
                fillOpacity={
                  hoveredBarIndex === null || hoveredBarIndex === index
                    ? 1
                    : 0.5
                }
                onMouseEnter={() => setHoveredBarIndex(index)}
                onMouseLeave={() => setHoveredBarIndex(null)}
              />
            ))}
          </Bar>
          {hoveredBarIndex === null && showYaxis && (
            <ReferenceLine
              y={averageValue}
              stroke="#F27521"
              strokeWidth={1}
              strokeDasharray="4 4"
              label={<CustomReferenceLineLabel value={averageValue} />}
              ifOverflow="extendDomain"
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

BarChartComponent.defaultProps = {
  gradientStart: "#009A51",
  gradientEnd: "#90DEB9",
  width: 500,
  height: 300,
  showXaxis: true,
  showYaxis: true,
  spaceBetweenBars: 30,
  showCartesianGrid: false,
  axisColor: "#DDDDDD",
  axisTextColor: "#868686",
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  barThickness: 30,
};

export default BarChartComponent;
