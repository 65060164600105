import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import Bed from "../assets/images/smart-health/bed.svg";
import Ring from "../assets/images/smart-health/ring.svg";
import ClockSvg from "../assets/images/smart-health/clock.svg";
const RADIAN = Math.PI / 180;

const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    if (index === 1) {
        return (
            <g transform={`rotate(-60, ${cx}, ${cy})`}>
                <g
                    transform={`translate(${x - 10}, ${y - 2}) rotate(${-midAngle + 12})`}
                >
                    {/* <image xlinkHref={Bed} alt="Bed" width="16" height="16" /> */}
                </g>
                <g
                    transform={`translate(${cx + radius * Math.cos((-midAngle + percent * 360 - 10) * RADIAN) - 8}, 
                                          ${cy + radius * Math.sin((-midAngle + percent * 360 - 10) * RADIAN) - 6} ) 
                                          rotate(${-midAngle + percent * 300 + 90})`}
                >
                    {/* <image xlinkHref={Ring} alt="Ring" width="16" height="16" /> */}
                </g>
            </g>
        );
    }
    return null;
};

const SleepClockV2 = ({
    data,
    colors = ["#0088FE", "#00C49F"],
    innerRadiusRatio = 0.42,
    outerRadiusRatio = 0.39,
    size = 300,
    clockSizeRatio = 0.7,
}) => {
    const outerRadius = size * outerRadiusRatio;
    const innerRadius = size * innerRadiusRatio;

    return (
        <div
            className="relative"
            style={{ width: `${size}px`, height: `${size}px` }}
        >
            <ResponsiveContainer width={size} height={size}>
                <PieChart>
                    <defs>
                        <filter
                            id="inset-shadow"
                            x="-50%"
                            y="-50%"
                            width="200%"
                            height="200%"
                        >
                            <feOffset dx="0" dy="4" />
                            <feGaussianBlur
                                stdDeviation="2"
                                result="offset-blur"
                            />
                            <feComposite
                                operator="out"
                                in="SourceGraphic"
                                in2="offset-blur"
                                result="inverse"
                            />
                            <feFlood
                                floodColor="black"
                                floodOpacity="0.25"
                                result="color"
                            />
                            <feComposite
                                operator="in"
                                in="color"
                                in2="inverse"
                                result="shadow"
                            />
                            <feComposite
                                operator="over"
                                in="shadow"
                                in2="SourceGraphic"
                            />
                        </filter>
                    </defs>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={innerRadius}
                        outerRadius={outerRadius}
                        startAngle={0}
                        paddingAngle={-10}
                        dataKey="value"
                        labelLine={false}
                        label={<CustomLabel />}
                        filter="url(#inset-shadow)"
                    >
                        {data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                                cornerRadius={10}
                            />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <div
                className="absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-center bg-no-repeat bg-contain"
                style={{
                    width: `${size * clockSizeRatio}px`,
                    height: `${size * clockSizeRatio}px`,
                    backgroundImage: `url(${ClockSvg})`,
                }}
            />
        </div>
    );
};

SleepClockV2.defaultProps = {
    data: [
        { name: "Time", value: 900 },
        { name: "Rest", value: 400 },
    ],
    colors: ["#F3F3F3", "#E9F2FF"],
    innerRadiusRatio: 0.32,
    outerRadiusRatio: 0.39,
    size: 300,
    clockSizeRatio: 0.7,
};

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
}

export default SleepClockV2;
