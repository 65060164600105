import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
const generateUniqueID = () => Math.random().toString(36).substr(2, 9);

const SunburstChart = ({ data, width = 300, height = 300 }) => {
    const processData = (
        data,
        level = 0,
        parentName = "",
        parentGradient = {}
    ) => {
        let result = [];
        data.forEach((item) => {
            const name = parentName
                ? `${parentName} > ${item.name}`
                : item.name;
            const gradientStart =
                item.gradientStart || parentGradient.gradientStart;
            const gradientEnd = item.gradientEnd || parentGradient.gradientEnd;

            if (item.children) {
                const value = item.children.reduce(
                    (sum, child) => sum + (child.value || 0),
                    0
                );
                result.push({
                    name: name,
                    value: value,
                    level: level,
                    gradientStart: gradientStart,
                    gradientEnd: gradientEnd,
                    gradientId: generateUniqueID(),
                });
                result = result.concat(
                    processData(item.children, level + 1, name, {
                        gradientStart,
                        gradientEnd,
                    })
                );
            } else {
                result.push({
                    ...item,
                    name: name,
                    level: level,
                    gradientStart: gradientStart,
                    gradientEnd: gradientEnd,
                    gradientId: generateUniqueID(),
                });
            }
        });
        return result;
    };

    const flatData = processData(data);
    const maxLevel = Math.max(...flatData.map((d) => d.level));

    const levelsData = [];
    for (let i = 0; i <= maxLevel; i++) {
        levelsData.push(flatData.filter((d) => d.level === i));
    }
    const radiusStep = 30 / (maxLevel + 1);
    const baseRadius = 20;
    const radii = levelsData.map((_, index) => ({
        innerRadius: `${baseRadius + index * radiusStep}%`,
        outerRadius: `${baseRadius + (index + 1) * radiusStep}%`,
    }));

    // Define opacity for each level
    const opacityStep = 1 / (maxLevel + 1);
    const opacities = levelsData.map((_, index) => 1 - index * opacityStep);

    // Collect all gradients from the data
    const gradients = flatData.reduce((acc, item) => {
        if (item.gradientStart && item.gradientEnd) {
            acc.push({
                id: item.gradientId,
                gradientStart: item.gradientStart,
                gradientEnd: item.gradientEnd,
            });
        }
        return acc;
    }, []);

    return (
        <div className="bg-white">
            <ResponsiveContainer width={width} height={height}>
                <PieChart>
                    <defs>
                        {gradients.map((gradient) => (
                            <linearGradient
                                key={gradient.id}
                                id={gradient.id}
                                x1="0"
                                y1="0"
                                x2="1"
                                y2="1"
                            >
                                <stop
                                    offset="0%"
                                    stopColor={gradient.gradientStart}
                                />
                                <stop
                                    offset="100%"
                                    stopColor={gradient.gradientEnd}
                                />
                            </linearGradient>
                        ))}
                    </defs>
                    {levelsData.map((levelData, index) => (
                        <Pie
                            key={`pie-${index}`}
                            data={levelData}
                            cx="50%"
                            cy="50%"
                            innerRadius={radii[index].innerRadius}
                            outerRadius={radii[index].outerRadius}
                            startAngle={90}
                            endAngle={-270}
                            dataKey="value"
                            isAnimationActive={false}
                            stroke="#fff"
                            labelLine={false}
                        >
                            {levelData.map((entry, idx) => {
                                const opacity = opacities[index];
                                let fillColor = "#cccccc";

                                if (entry.gradientId) {
                                    fillColor = `url(#${entry.gradientId})`;
                                }

                                return (
                                    <Cell
                                        key={`cell-${index}-${idx}`}
                                        fill={fillColor}
                                        fillOpacity={opacity}
                                    />
                                );
                            })}
                        </Pie>
                    ))}
                    <Tooltip
                        formatter={(value, name, props) => {
                            const unit = props.payload.unit
                                ? ` ${props.payload.unit}`
                                : "";
                            const label = name.split(" > ").pop();
                            return [`${value}${unit}`, label];
                        }}
                        labelFormatter={() => ""}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SunburstChart;
