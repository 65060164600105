import React from "react";

const StressGraphV2 = ({
  percentage = 20,
  gradientStart = "#009A51",
  gradientEnd = "#B0E0C9",
  width = 127,
  height = null,
  centerText = "Good",
  strokeWidth = 10,
  centerFontSize = 12,
  percentageFontSize = 12,
  textColor = "#C7C7C7",
  percentageTextColor = "#009A51",
}) => {
  const gradientId = React.useId();

  const radius = (width - strokeWidth) / 2;
  const center = { x: width / 2, y: width / 2 };
  const startAngle = 150;
  const endAngle = -150;
  const angleRange = Math.abs(startAngle - endAngle);
  const filledAngle = startAngle - (percentage / 100) * angleRange;

  const polarToCartesian = (angle, radius) => {
    const angleInRadians = ((angle - 90) * Math.PI) / 180.0;
    return {
      x: center.x + radius * Math.cos(angleInRadians),
      y: center.y + radius * Math.sin(angleInRadians),
    };
  };

  const arcPath = (start, end, radiusOffset = 0) => {
    const r = radius + radiusOffset;
    const startPoint = polarToCartesian(start, r);
    const endPoint = polarToCartesian(end, r);
    const largeArcFlag = Math.abs(end - start) <= 180 ? "0" : "1";
    return `M ${startPoint.x} ${startPoint.y} A ${r} ${r} 0 ${largeArcFlag} 0 ${endPoint.x} ${endPoint.y}`;
  };

  const renderTicks = () => {
    const majorTickCount = 9;
    const minorTicksPerMajor = 4;
    const ticks = [];

    for (let i = 0; i <= majorTickCount; i++) {
      const angle = startAngle - (i / majorTickCount) * angleRange;
      const outerPoint = polarToCartesian(angle, radius + strokeWidth / 3);
      const innerPoint = polarToCartesian(angle, radius - strokeWidth / 3);

      ticks.push(
        <line
          key={`major-${i}`}
          x1={outerPoint.x}
          y1={outerPoint.y}
          x2={innerPoint.x}
          y2={innerPoint.y}
          stroke="#86909C"
          strokeWidth="1.25"
        />
      );

      if (i < majorTickCount) {
        for (let j = 1; j <= minorTicksPerMajor; j++) {
          const minorAngle =
            angle -
            (j / (minorTicksPerMajor + 1)) * (angleRange / majorTickCount);
          const outerMinorPoint = polarToCartesian(
            minorAngle,
            radius + strokeWidth / 3 - 2
          );
          const innerMinorPoint = polarToCartesian(
            minorAngle,
            radius - strokeWidth / 3 + 2
          );

          ticks.push(
            <line
              key={`minor-${i}-${j}`}
              x1={outerMinorPoint.x}
              y1={outerMinorPoint.y}
              x2={innerMinorPoint.x}
              y2={innerMinorPoint.y}
              stroke="#C9CDD4"
              strokeWidth="1"
            />
          );
        }
      }
    }

    return ticks;
  };

  const percentagePosition = polarToCartesian(endAngle - 30, radius - 20);

  const calculatedHeight = height || width;

  return (
    <svg
      width={width}
      height={calculatedHeight}
      viewBox={`0 0 ${width} ${calculatedHeight}`}
      preserveAspectRatio="xMidYMid meet"
      aria-label="Stress Graph"
    >
      <defs>
        <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <linearGradient id={gradientId} x1="1" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={gradientStart} />
          <stop offset="100%" stopColor={gradientEnd} />
        </linearGradient>
        <filter id="innerShadow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" result="blur" />
          <feOffset dy="2" />
          <feComposite
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowDiff"
          />
          <feFlood floodColor="#000000" floodOpacity="0.5" />
          <feComposite in2="shadowDiff" operator="in" />
          <feComposite in2="SourceGraphic" operator="over" />
        </filter>
      </defs>

      <g transform={`translate(0, 20)`} filter="url(#dropShadow)">
        <path
          d={arcPath(startAngle, endAngle)}
          fill="none"
          stroke="#ffffff"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          filter="url(#innerShadow)"
        />
        <path
          d={arcPath(startAngle, filledAngle)}
          fill="none"
          stroke={`url(#${gradientId})`}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
        />

        {renderTicks()}

        <text
          x={center.x}
          y={center.y}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={centerFontSize}
          fontWeight="bold"
          fill={textColor}
        >
          {centerText}
        </text>
        <text
          x={percentagePosition.x}
          y={percentagePosition.y}
          textAnchor="middle"
          dominantBaseline="central"
          fontSize={percentageFontSize}
          fill={percentageTextColor}
        >
          {percentage}%
        </text>
      </g>
    </svg>
  );
};

export default StressGraphV2;
