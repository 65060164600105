import clevertap from "clevertap-web-sdk";

export function initializeCleverTap(isDebug = false) {
  clevertap.privacy.push({ optOut: false });
  clevertap.privacy.push({ useIP: false });
  clevertap.init(
    import.meta.env.VITE_CLEVERTAP_ACCOUNT_ID,
    import.meta.env.VITE_CLEVERTAP_REGION_CODE
  );
  clevertap.spa = true;

  clevertap.setLogLevel(isDebug ? 3 : 0);
}
