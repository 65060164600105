import React from "react";
import {
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const HealthRadarChart = ({
  data = [
    { metric: "Heart Rate (HR)", value: 78, maxValue: 220 },
    { metric: "Heart Rate Variability (HRV)", value: 45, maxValue: 100 },
    { metric: "Blood Oxygen (SpO2)", value: 97, maxValue: 100 },
    { metric: "SDNN", value: 6.2, maxValue: 50 },
  ],
  height,
  size = 300,
  startGradient = "#00C6FB",
  endGradient = "#005BEA",
}) => {
  const processedData = data.map((item) => ({
    metric: item.metric,
    value: (item.value / item.maxValue) * 100,
    originalValue: item.value,
  }));

  return (
    <ResponsiveContainer width="100%" height={height ?? "100%"}>
      <RadarChart data={processedData} outerRadius="80%">
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor={startGradient} />
            <stop offset="100%" stopColor={endGradient} />
          </linearGradient>
        </defs>
        <PolarGrid />
        <PolarAngleAxis
          dataKey="metric"
          tick={{ fontSize: 12, marginBottom: 10 }}
          tickMargin={10}
        />
        <PolarRadiusAxis tick={{ fontSize: 10 }} />
        <Radar
          name="Health Data"
          dataKey="value"
          stroke="url(#colorGradient)"
          fill="url(#colorGradient)"
          fillOpacity={0.6}
        />
        <Tooltip content={<CustomTooltip />} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const CustomDot = (props) => {
  const { cx, cy, payload } = props;
  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill="#8884d8"
        stroke="#fff"
        strokeWidth={1}
      />
      <text x={cx} y={cy - 10} textAnchor="middle" fontSize={12} fill="#333">
        {payload.originalValue}
      </text>
    </g>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { metric, originalValue } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <p style={{ margin: 0, fontWeight: "bold" }}>{metric}</p>
        <p style={{ margin: 0 }}>{`Value: ${originalValue}`}</p>
      </div>
    );
  }
  return null;
};

export default HealthRadarChart;
