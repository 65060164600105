import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const PieChartComponent = ({
  percentage,
  gradientStart,
  gradientEnd,
  innerRadius,
  outerRadius,
  width,
  height,
}) => {
  const gradientId = crypto.randomUUID();

  const data = [
    { name: "Remaining", value: 100 - percentage },
    { name: "Filled", value: percentage },
  ];

  const COLORS = ["#e0e0e0", `url(#${gradientId})`];

  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart>
        <defs>
          <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor={gradientStart} stopOpacity={1} />
            <stop offset="100%" stopColor={gradientEnd} stopOpacity={1} />
          </linearGradient>
        </defs>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={90}
          endAngle={-270}
          paddingAngle={-10}
          dataKey="value"
          radius={100}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index]}
              cornerRadius={index === 1 ? 10 : 0}
            />
          ))}
        </Pie>
      </PieChart>
      <div
        className="flex justify-center align-center mt-10 text-center"
        style={{
          marginTop: `-${height / 1.7}px`,
        }}
      >
        <span className="text-body-large font-bold text-slate-800">
          {percentage}%
        </span>
      </div>
    </ResponsiveContainer>
  );
};

PieChartComponent.defaultProps = {
  gradientStart: "#009A51",
  gradientEnd: "#90DEB9",
  innerRadius: "70%",
  outerRadius: "100%",
  width: 150,
  height: 150,
};

export default PieChartComponent;
