import React from "react";
import { RadialBarChart, RadialBar, ResponsiveContainer } from "recharts";

const RadialBarChartComponent = ({ data, gradientStart, gradientEnd }) => {
  return (
    <div className="bg-white">
      <ResponsiveContainer
        width={200}
        height={200}
        className="w-full h-full flex-none"
      >
        <RadialBarChart
          data={data}
          cx="50%"
          cy="50%"
          innerRadius="40%"
          outerRadius="100%"
          barSize={10}
          startAngle={90}
          endAngle={-270}
        >
          <defs>
            <linearGradient id="customGradient" x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor={gradientStart} stopOpacity={1} />
              <stop offset="100%" stopColor={gradientEnd} stopOpacity={1} />
            </linearGradient>
          </defs>
          <RadialBar
            minAngle={15}
            background={{ fill: "#e0e0e0" }}
            clockWise
            cornerRadius={30}
            dataKey="value"
            fill="url(#customGradient)"
          />
        </RadialBarChart>
      </ResponsiveContainer>
    </div>
  );
};

RadialBarChartComponent.defaultProps = {
  gradientStart: "#1F80FA",
  gradientEnd: "#FF6347",
};

export default RadialBarChartComponent;
