import React from "react";
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { formattedNumber } from "../utilities/helper";

const CustomDot = ({ cx, cy, stroke, gradientStart }) => (
  <g>
    <circle
      cx={cx}
      cy={cy}
      r={8}
      stroke={stroke || gradientStart}
      strokeWidth={2}
      fill={gradientStart}
    />
    <circle cx={cx} cy={cy} r={3} fill="#FFFFFF" />
  </g>
);

const CustomReferenceLineLabel = ({ viewBox, value }) => {
  const { x, y, width } = viewBox;
  const labelX = x + width / 2;
  const labelY = y;

  return (
    <g>
      <rect
        x={labelX - 50}
        y={labelY - 10}
        width={100}
        height={25}
        fill="#F27521"
        rx={15}
        ry={15}
      />
      <text
        x={labelX}
        y={labelY + 6}
        textAnchor="middle"
        fill="#fff"
        fontSize="12"
      >
        {`Avg ${value}`}
      </text>
    </g>
  );
};

const ComposedAreaLineChart = ({
  data,
  gradientStart,
  gradientEnd,
  width,
  height,
  showXaxis = true,
  showYaxis = true,
  showCartesianGrid = false,
  axisColor = "#DDDDDD",
  axisTextColor = "#868686",
  margin = { top: 0, right: 0, left: 0, bottom: 0 },
  lineThickness = 4,
  lineType = "monotone",
  dataKey = "value",
  yAxisKey = "name",
}) => {
  const gradientId = `gradient_${Math.random().toString(36).substr(2, 9)}`;

  const averageValue = formattedNumber(
    data.reduce((sum, entry) => sum + entry.value, 0) / data.length
  );

  return (
    <div
      className={`w-full bg-white ${showYaxis ? "border-l-2" : ""}`}
      style={{ borderColor: axisColor }}
    >
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart data={data} margin={margin}>
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="1" y2="1">
              <stop offset="0%" stopColor={gradientStart} stopOpacity={1} />
              <stop offset="100%" stopColor={gradientEnd} stopOpacity={0} />
            </linearGradient>
          </defs>
          {showCartesianGrid && (
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
          )}
          {showXaxis && (
            <XAxis
              dataKey={yAxisKey}
              stroke={axisColor}
              tick={{ fill: axisTextColor }}
              tickMargin={10}
              tickLine={false}
              className="text-body-small"
            />
          )}
          {showYaxis && (
            <YAxis
              orientation="right"
              stroke={axisColor}
              tick={{ fill: axisTextColor }}
              tickMargin={10}
              axisLine={false}
              tickLine={{ stroke: axisColor }}
              className="text-body-small"
            />
          )}
          <Tooltip />

          <Area
            type={lineType}
            dataKey={dataKey}
            stroke="none"
            fillOpacity={0.2}
            fill={`url(#${gradientId})`}
          />
          <Line
            type={lineType}
            dataKey={dataKey}
            stroke={gradientStart}
            strokeWidth={lineThickness}
            dot={false}
            activeDot={<CustomDot gradientStart={gradientStart} />}
            style={{
              filter: "drop-shadow(0 0 4px rgba(0, 0, 0, 0.2))",
            }}
          />
          <ReferenceLine
            y={averageValue}
            stroke="#F27521"
            strokeWidth={1}
            type="dashed"
            strokeDasharray="4 4"
            label={<CustomReferenceLineLabel value={averageValue} />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

ComposedAreaLineChart.defaultProps = {
  gradientStart: "#FA7783",
  gradientEnd: "#FA7783",
  width: "100%",
  height: 300,
  showXaxis: true,
  showYaxis: true,
  showCartesianGrid: false,
  axisColor: "#DDDDDD",
  axisTextColor: "#868686",
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineThickness: 3,
  lineType: "monotone",
};

export default ComposedAreaLineChart;
