import React from "react";
import blue_arrow from "../assets/images/blue_arrow.svg";

function SecondaryButton({
  onClick,
  icon = blue_arrow,
  text,
  customClass = "mt-4",
  backgroundColor = "bg-white",
  textColor = "text-primary",
  iconStyle = {},
  keyName = "",
  showIcon = false,
  width = "w-fit",
  justify = "justify-center",
  paddingY = "py-2",
  disabled = false,
}) {
  return (
    <div
      key={keyName}
      className={`${width} px-4 ${paddingY} flex ${justify} ${customClass} ${backgroundColor} items-center rounded-lg gap-2.5 ${
        disabled ? "cursor-not-allowed disabled-icon" : "cursor-pointer"
      } border border-gray-300`}
      onClick={disabled ? undefined : onClick}
    >
      <div className={`${textColor} text-label-large`}>{text}</div>
      {showIcon && (
        <img style={iconStyle} src={icon} className="w-6 h-6 relative" />
      )}
    </div>
  );
}

export default SecondaryButton;
